<template>
  <div>
    <h1 class="mb-m">Журнал изменений</h1>

    <ChangelogFilter class="mb-l" @request="loadFilter" @reset="resetFilter" />

    <AppTable
      :columns="columns"
      :items="rows"
      :loading="isLoading"
      rowActive
      :isChangelog="true"
      @rowClick="openTable"
    />

    <AppPagination
      v-if="pagination.pageCount > 1"
      :current-page="pagination.page"
      :page-count="pagination.pageCount"
      :disabled="isLoading"
      @setPage="loadPage"
    />

    <ModalTable v-if="isModal" :item="currentTable" @close="closeModal" />
  </div>
</template>

<script>
import { ChangelogFilter } from '@/features/load-calendar/changelog-filter'
import AppPagination from '@/components/ui/AppPagination.vue'
import AppTable from '@/components/ui/AppTable.vue'
import ModalTable from '@/entities/load-calendar/ui/ModalTable.vue'

import { mixinIsLoading } from '@/mixins/mixinIsLoading'

import { LoadCalendarModel } from '@/entities/load-calendar'
import { LoadCalendarChangelogApi } from '@/api/calendar/LoadCalendarChangelog'
import { showAlertError } from '@/utils/store/alert'
import { getPageCount } from '@/utils/store/pagination'
import { findById } from '@/utils/array/find'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { formatDateTime } from '@/utils/format/date'
import { mapRange } from '@/shared/lib/utils/map/to-string'
import { getUserFullName } from '@/utils/model/User'

export default {
  name: 'ChangelogPage',
  mixins: [mixinIsLoading, mixinModal],
  components: {
    ModalTable,
    AppTable,
    AppPagination,
    ChangelogFilter
  },
  data: () => ({
    columns: [
      {
        title: 'Период',
        name: 'period'
      },
      {
        title: 'Цех',
        name: 'calendarTab'
      },
      {
        title: 'Taблица',
        name: 'type'
      },
      {
        title: 'Кто изменил',
        name: 'whoChanged'
      }
    ],
    rows: [],

    currentTable: null,

    filter: {},
    pagination: {
      perPage: 20,
      page: 1,
      pageCount: 1
    }
  }),

  computed: {
    tabs () {
      return this.$store.state.handbook.tabTableTimeAndLoad.list
    }
  },
  async created () {
    await this.getTabAll()
    this.getGOSTAll()
    this.getChangelogAll()
  },

  methods: {
    async getTabAll () {
      await this.$store.dispatch('handbook/tabTableTimeAndLoad/loadList')
    },

    loadFilter (data) {
      this.filter = data
      this.setPage(1)
      this.getChangelogAll()
    },
    resetFilter () {
      this.filter = {}
      this.setPage(1)
      this.getChangelogAll()
    },

    loadPage (num) {
      this.setPage(num)
      this.getChangelogAll()
    },

    async getChangelogAll () {
      try {
        this.startLoading()
        this.resetRows()

        const response = await LoadCalendarChangelogApi.getAll({
          'per-page': this.pagination.perPage,
          page: this.pagination.page,
          ...this.filter
        })

        this.setRows(response.data)
        this.pagination.pageCount = getPageCount(response)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    setRows (data) {
      this.rows = data.map(item => {
        const type = findById(LoadCalendarModel.TABLE_TYPES, item.type_id)
          ?.name

        const from = formatDateTime(item.created_at)
        const to = item.removed_at ? formatDateTime(item.removed_at) : ''

        const period = mapRange({ from, to })

        const calendarTab = findById(this.tabs, item.calendar_tab_id)?.name

        const whoChanged = item.user ? getUserFullName(item.user) : '-'

        return {
          ...item,
          period,
          calendarTab,
          type,
          whoChanged
        }
      })
    },

    resetRows () {
      this.rows.length = 0
    },

    setPage (num) {
      this.pagination.page = num
    },

    openTable (item) {
      this.currentTable = item
      this.openModal()
    },

    closeModalTable () {
      this.closeModal()
      this.currentTable = null
    },

    getGOSTAll () {
      this.$store.dispatch('handbook/gost/loadList')
    }
  }
}
</script>
