import { getAll, getById } from '@/api/request'
import { throwResponseError } from '@/utils/error/throwResponseError'

const LOAD_CALENDAR_CHANGELOG_URL = 'calendar/history/tables'

export const LoadCalendarChangelogApi = {
  getAll: async (params) => {
    try {
      return await getAll(LOAD_CALENDAR_CHANGELOG_URL, params)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить журнал изменений')
    }
  },

  getById: async (id, params) => {
    try {
      return await getById(LOAD_CALENDAR_CHANGELOG_URL, id, params)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить запись журнала изменений')
    }
  }
}
