<template>
  <BaseModal class="modal-calendar-table" :title="item.period" @close="$emit('close')">
    <WaitingForLaunchTable
      v-if="isWaitingForLaunchTable(item.type_id)"
      :rows="mappedItem.table.rows"
      :orders-amount="mappedItem.table.order_amount"
    />

    <CurrentLoadTable
      v-else-if="isCurrentLoadTable(item.type_id)"
      :rows="mappedItem.table.rows"
    />

    <ProductionTimeTable
      v-else
      :head="mappedItem.table.head"
      :columns="mappedItem.table.columns"
      :rows="mappedItem.table.rows"
      :table-type="item.type_id"
      visible-title
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import CurrentLoadTable from '@/entities/load-calendar/ui/table/CurrentLoadTable.vue'
import ProductionTimeTable from '@/entities/load-calendar/ui/table/ProductionTimeTable.vue'
import WaitingForLaunchTable from '@/entities/load-calendar/ui/table/WaitingForLaunchTable.vue'

import { LoadCalendarModel } from '@/entities/load-calendar'

export default {
  components: {
    WaitingForLaunchTable,
    ProductionTimeTable,
    CurrentLoadTable,
    BaseModal
  },
  provide () {
    return {
      provideData: this.provideData
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    provideData: {
      windingMethodsFiltered: []
    }
  },

  data: () => ({
    mappedItem: {}
  }),

  computed: {
    isWaitingForLaunchTable () {
      return LoadCalendarModel.isWaitingForLaunchTable
    },
    isCurrentLoadTable () {
      return LoadCalendarModel.isCurrentLoadTable
    }
  },

  created () {
    this.setMappedItem()
  },

  methods: {
    setMappedItem () {
      const item = {
        type_id: this.item.type_id,
        table: {
          ...this.item.table_value
        }
      }

      this.mappedItem = LoadCalendarModel.mapTable(item)
    }
  }
}
</script>

<style lang="scss">
.modal-calendar-table {
  .modal__content {
    max-width: 95%;
    max-height: 90vh;
    overflow: hidden;
  }

  .modal__title {
    max-width: initial;
  }

  .custom-table__wrapper {
    // высота (modal__content) - (padding modal__content) - (modal__title) - (custom-table title) - (custom-table gap)
    max-height: calc(90vh - 60px - 48px - 28px - 20px - 1px);
  }

  .custom-table__head {
    position: sticky;
    top: 0;
    z-index: 2;
    border-top: 1px solid var(--color__gray-blue);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    tr:first-child {
      th {
        border-top: 1px solid var(--color__gray-blue);
      }

      th:first-child {
        border-top-left-radius: inherit;
      }

      th:last-child {
        border-top-right-radius: inherit;
      }
    }
  }
}
</style>
